import React, { memo, useMemo, useState } from "react";
import { Button, Card as CardBootstrap, Spinner } from "react-bootstrap";
import styled from "styled-components";
import OrderModal from "./OrderModal";

const OrderCard = memo(
  ({ order, status: statusBase, onActionClick, onRouteClick }) => {
    // States
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [loadingRoute, setLoadingRoute] = useState(false);

    // Effects
    // useEffect(() => {
    //   console.debug("OrderCard render");
    // });

    const status = useMemo(
      () =>
        statusBase || {
          color: "primary",
          text: "Iniciar",
          cursor: false,
        },
      [statusBase]
    );

    // Handles
    const handleCardClick = (event) => {
      event.stopPropagation();
      setShowOrderModal(true);
    };

    const handleActionClick = async (event) => {
      setLoadingAction(true);
      onActionClick && (await onActionClick(event));
      setLoadingAction(false);
    };

    const handleRouteClick = async (event) => {
      event.stopPropagation();

      setLoadingRoute(true);
      onRouteClick && (await onRouteClick(event));
      setLoadingRoute(false);
    };

    const handleCloseModal = () => {
      setShowOrderModal(false);
    };

    return (
      order &&
      status && (
        <>
          <Card border={status.color} bg="green-light" onClick={handleCardClick}>
            <Header style={{ fontSize: 16 }}> 
              {order.orderNumber && "#" + order.orderNumber} - {order.shippingPerson && order.shippingPerson.name}
              {/* {onActionClick ? (
                <Button
                  variant={status.color}
                  size="sm"
                  onClick={handleActionClick}
                  style={{
                    cursor: status.cursor ? "pointer" : "initial",
                    padding: 12,
                    fontSize: 12,
                  }}
                >
                  {loadingAction ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    status.text
                  )}
                </Button>
              ) : (
                <></>
              )} */}
            </Header>

            <Body style={{ fontSize: 12 }}>
              {(order.orderType && order.orderType === "pickup") ||
                (order.paymentMethod === "Efectivo" && (
                  <>
                    <strong>Delivery</strong>
                    <br></br>
                  </>
                ))}
              {/*<strong>
                {order.shippingPerson && order.shippingPerson.name}
              </strong>
              <br></br>
              <strong>
                {order.shippingPerson && order.shippingPerson.companyName}
                </strong>*/}
              <div>
                {/*<ItemsNumber>{order.items.length} Artículos</ItemsNumber>*/}
                {order.items.map((item) => (
                  <div key={item.id}>
                    <strong>{item.quantity} </strong> x {item.name}
                    {/*item.selectedOptions && item.selectedOptions.length && (
                      <UIStyle>
                        {item.selectedOptions.map((option, indexOption) => (
                          <li key={indexOption}>
                            {option.name}: {option.value}
                          </li>
                        ))}
                      </UIStyle>
                        )*/}
                  </div>
                ))}
              </div>
            </Body>

            {onRouteClick ? (
              <Footer>
                &nbsp;
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleRouteClick}
                  style={{ padding: 12, fontSize: 12 }}
                >
                  {loadingRoute ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Ver ruta"
                  )}
                </Button>
              </Footer>
            ) : (
              <></>
            )}
          </Card>

          <OrderModal
            show={showOrderModal}
            order={{ ...order }}
            status={status}
            onActionClick={handleActionClick}
            onClose={handleCloseModal}
          />
        </>
      )
    );
  }
);

const Card = styled(CardBootstrap)`
  width: 13rem;
  margin-bottom: 3px;
`;

const Header = styled(CardBootstrap.Header)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Body = styled(CardBootstrap.Body)``;

const ItemsNumber = styled.div`
  color: gray;
  font-size: 11px;
`;

const UIStyle = styled.ul`
  margin: 0;
`;

const Footer = styled(Header)``;

export default OrderCard;
