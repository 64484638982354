import Section from "../Section";
import Container from "react-bootstrap/Container";
import React, { useEffect, useState } from "react";
import "react-pivottable/pivottable.css";
import Ecwid from "../../ecwid";
import { Button, Spinner, Col, Row, Table } from "react-bootstrap";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Form from "react-bootstrap/Form";
import PageLoader from "../PageLoader";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from "react-pivottable/TableRenderers";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import Plot from "react-plotly.js";


const PlotlyRenderers = createPlotlyRenderers(Plot);

function Inventory(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState({});
  //const [categories, setCategories] = useState([]);

  const getProducts=async()=>{

    let categoriesFilter=[]
    let resultCategories = await Ecwid.getCategories({ offset:0 });
    resultCategories.items.map((category)=>{
      categoriesFilter.push({id: category.id, name: category.name})
    })


    let productsFilter =[]
    let resultProducts = await Ecwid.searchProducts({
      offset:0, 
      enabled:true,
    });
    const auxProducts1=resultProducts.items;
    resultProducts = await Ecwid.searchProducts({
      offset:100, 
      enabled:true,
    });
    const auxProducts2=resultProducts.items;
    let auxProducts=[]
    if(auxProducts1 && auxProducts2){
      auxProducts = auxProducts1.concat(auxProducts2)
    }

    auxProducts.map((producto)=>{
      let cat='Sin Categoria'
      categoriesFilter.map((categoria)=>{
        if(categoria.id === producto.categories[0]?.id){
          cat = categoria.name
        }
        //console.log(categoria)
      })
      productsFilter.push({
        categories: cat,
        name: producto.name,
        price: producto.price,
        quantity: producto.quantity,
        inStock: producto.inStock,
      })
    })

    setProducts(productsFilter)
    //setProducts(auxProducts)
  }

  useEffect(()=>{
    setIsLoading(true);
    getProducts();
    setIsLoading(false);
   },[])
  //________________________________________

  return isLoading ? (
    <PageLoader />
  ):(
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      padding={8}
    >
      
      <Container fluid>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <Form style={{ padding: 16 }}>
              <PivotTableUI 
                data={products}
                onChange={(attr)=> {
                  setOptions(attr);
                }}
                renderers={Object.assign({},TableRenderers, PlotlyRenderers)}
                {...options}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default Inventory;
