import Section from "../Section";
import Container from "react-bootstrap/Container";
import React, { useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import Ecwid from "../../ecwid";
import { Button, Spinner, Col, Row, Table } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { async } from "analytics/lib/analytics.cjs";

const PlotlyRenderers = createPlotlyRenderers(Plot);

function PivotTable(props) {
  const [options, setOptions] = useState({});
  const [orders, setOrders] = useState([]);
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getOrders = async () => {
    setIsLoading(true);
    console.log("Cargando resultados...");

    //Reset values
    setOptions({});
    setOrders([]);
    setSales([]);

    // Get Orders
    let totalOrders = 0;
    let resultOrders = await Ecwid.searchOrders({
      offset: 0,
    });
    totalOrders = resultOrders.total;
    var orderTotal = orders.concat(resultOrders.items);
    var ordersWithDate = [];

    for (let i = 100; i < totalOrders; i = i + 100) {
      resultOrders = await Ecwid.searchOrders({ offset: i });
      orderTotal = orderTotal.concat(resultOrders.items);
    }
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    console.log("orderTotal: ", orderTotal);

    for (let order of orderTotal) {
      var fecha = order.createDate.replace(" +0000", "");
      fecha = fecha.replace(" ", "T");
      fecha = new Date(fecha);
      fecha.setHours(fecha.getHours() - 6);
      fecha = moment(fecha).format("YYYY-MM-DD");
      if (fecha >= start && fecha <= end) {
        ordersWithDate = ordersWithDate.concat(order);
      }
    }

    setOrders(ordersWithDate);
    console.log("ordersWithDate: ", ordersWithDate);

    // Get Categories
    let resultCategories = await Ecwid.getCategories();

    // Get Products
    let totalProducts = 0;
    let resultProducts = await Ecwid.getProducts({ offset: 0 });
    totalProducts = resultProducts.total;
    var productTotal = orders.concat(resultProducts.items);
    for (let i = 100; i < totalProducts; i = i + 100) {
      resultProducts = await Ecwid.searchProducts({ offset: i });
      productTotal = productTotal.concat(resultProducts.items);
    }
    var salesTotal = sales;
    var discountPartial = 0;
    for (let order of ordersWithDate) {
      for (let item of order.items) {
        if (order.discount > 0) {
          discountPartial = order.discount / order.items.length;
          item.totalPrice = item.price * item.quantity;
          item.totalPrice = item.totalPrice - discountPartial;
        } else if (order.couponDiscount > 0) {
          discountPartial = order.couponDiscount / order.items.length;
          item.totalPrice = item.price * item.quantity;
          item.totalPrice = item.totalPrice - discountPartial;
        } else if (order.discountCoupon) {
          discountPartial = (order.discountCoupon.discount * item.price) / 100;
          discountPartial = discountPartial / order.items.length;
          item.totalPrice = item.price * item.quantity;
          item.totalPrice = item.totalPrice - discountPartial;
        } else {
          item.totalPrice = item.price * item.quantity;
        }

        item.productName = item.name;
        item.urlProductImg = item.hdThumbnailUrl;

        //delete elements
        delete item.name;
        delete item.price;
        delete item.hdThumbnailUrl;
        delete item.smallThumbnailUrl;
        delete item.selectedOptions;
        delete item.imageUrl;
        delete item.isCustomerSetPrice;
        delete item.isShippingRequired;
        delete item.quantityInStock;
        delete item.digital;
        delete item.dimensions;
        delete item.fixedShippingRate;
        delete item.fixedShippingRateOnly;
        delete item.nameTranslated;
        delete item.productAvailable;
        delete item.productPrice;
        delete item.shipping;
        delete item.shortDescriptionTranslated;
        delete item.tax;
        delete item.taxable;
        delete item.trackQuantity;
        delete item.weight;

        if (item.categoryId) {
          for (let category of resultCategories.items) {
            if (category.id === item.categoryId) {
              item.categoryName = category.name;
            }
          }
        } else if (!item.categoryId) {
          for (let product of productTotal) {
            if (product.id === item.productId) {
              for (let category of resultCategories.items) {
                if (product.categories && product.categories.length > 0) {
                  if (category.id === product.categories[0].id) {
                    item.categoryName = category.name;
                  }
                }
              }
            }
          }
        }
      }
      salesTotal = salesTotal.concat(order.items);
    }
    setSales(salesTotal);
    setIsLoading(false);
    console.log("salesTotal: ", salesTotal);
  };

  const resetSearch = async () => {
    setSales([]);
    setOrders([]);
  };

  //DatePicker
  const handleSelect = (ranges) => {
    setStartDate(ranges.startDate);
    setEndDate(ranges.endDate);
  };

  //________________________________________

  return (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      padding={8}
    >
      <Container fluid>
        <Form style={{ padding: 16 }}>
          {sales && sales.length === 0 && (
            <>
              <DateRangePicker
                ranges={[
                  {
                    startDate: startDate,
                    endDate: endDate,
                    key: "selection",
                  },
                ]}
                rangeColors={["#325454"]}
                onChange={(e) => handleSelect(e.selection)}
              />

              <Button
                size="sm"
                onClick={() => getOrders()}
                style={{
                  padding: 12,
                  fontSize: 14,
                  marginTop: 12,
                  marginLeft: 16,
                }}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Buscar"
                )}
              </Button>
            </>
          )}
        </Form>
        <Form>
          {sales && sales.length && !isLoading ? (
            <>
              <Button
                size="sm"
                onClick={() => resetSearch()}
                style={{
                  padding: 12,
                  fontSize: 14,
                  marginBottom: 8,
                }}
              >
                Nueva Búsqueda
              </Button>
              <PivotTableUI
                data={sales}
                onChange={(attr) => {
                  setOptions(attr);
                }}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...options}
              />
            </>
          ) : null}
        </Form>
      </Container>
    </Section>
  );
}

export default PivotTable;
