import React from "react";
import PivotTableSection from "../components/PivotTable";
import { requireAuth } from "../util/auth.js";

const PivotTablePage = ({ setTitle }) => {
  setTitle("Estadísticas");

  return (
    <PivotTableSection
      bg="white"
      textColor="dark"
      title="Estadísticas"
      subtitle=""
    />
  );
};

export default requireAuth(PivotTablePage);
