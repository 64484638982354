import React from "react";
import RecipesList from "../components/Recipes/recipesIndex";
import { requireAuth } from "../util/auth.js";

const recipesList = ({ setTitle }) => {
  setTitle("Recetas");

  return (
    <RecipesList bg="white" textColor="dark" title="recetas" subtitle="" />
  );
};

export default requireAuth(recipesList);
