import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import { ToastProvider } from "react-toast-notifications";
import { ProvideAuth } from "./util/auth.js";

ReactDom.render(
  <ProvideAuth>
    <ToastProvider>
      <App />
    </ToastProvider>
  </ProvideAuth>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
