import React from "react";
import CreateRecipesForm from "../components/Ingredients/createIngredientsForm";
import { requireAuth } from "../util/auth.js";

const createRecipes = ({ setTitle }) => {
  setTitle("Crear Ingrediente");

  return (
    <CreateRecipesForm
      bg="white"
      textColor="dark"
      title="ingredientes"
      subtitle=""
    />
  );
};

export default requireAuth(createRecipes);
