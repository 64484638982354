import React from "react";
import TrackingSection from "../components/Tracking";
import { requireAuth } from "../util/auth.js";
import { useRouter } from "../util/router";

const TackingPage = ({ setTitle }) => {
  setTitle("Rastreo");
  const router = useRouter();

  return (
    <TrackingSection
      bg="white"
      textColor="dark"
      title="Tracking"
      subtitle=""
      id={router.query.id}
    />
  );
};

export default requireAuth(TackingPage);
