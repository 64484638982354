import React, { createContext, useState } from "react";

const groupContext = createContext();
const useProvideGroup = (groupsBase) => {
  const [groups, setGroups] = useState(groupsBase);

  const getGroup = (groupId) => {
    const group = groups.find(({ id }) => id === groupId);

    return { ...group };
  };

  const addGroup = (group) => {
    setGroups([...groups, group]);
  };

  const updateGroup = (groupId, group) => {
    const newGroups = [...groups];
    const indexGroup = newGroups.findIndex(({ id }) => id === groupId);
    if (indexGroup >= 0) {
      newGroups.splice(indexGroup, 1, group);

      setGroups(newGroups);
    }
  };

  const removeGroup = (groupId) => {
    const newGroups = [...groups];
    const indexGroup = newGroups.findIndex(({ id }) => id === groupId);
    if (indexGroup >= 0) {
      newGroups.splice(indexGroup, 1);

      setGroups(newGroups);
    }
  };

  const getCard = (groupId, cardId) => {
    const group = getGroup(groupId);
    if (group && group.cards) {
      const card = group.cards.find(({ id }) => id === cardId);

      return { ...card };
    }

    return null;
  };

  const addCard = (groupId, card) => {
    const newGroups = [...groups];
    const indexGroup = newGroups.findIndex(({ id }) => id === groupId);
    if (indexGroup >= 0) {
      newGroups[indexGroup].cards = [
        ...(newGroups[indexGroup].cards || []),
        card,
      ];

      setGroups(newGroups);
    }
  };

  const updateCard = (groupId, cardId, card) => {
    const newGroups = [...groups];
    const indexGroup = newGroups.findIndex(({ id }) => id === groupId);
    if (indexGroup >= 0 && newGroups[indexGroup].cards) {
      const indexCard = newGroups[indexGroup].cards.findIndex(
        ({ id }) => id === cardId
      );

      if (indexCard >= 0) {
        newGroups[indexGroup].cards.splice(indexCard, 1, card);

        setGroups(newGroups);
      }
    }
  };

  const removeCard = (groupId, cardId) => {
    const newGroups = [...groups];
    const indexGroup = newGroups.findIndex(({ id }) => id === groupId);
    if (indexGroup >= 0 && newGroups[indexGroup].cards) {
      const indexCard = newGroups[indexGroup].cards.findIndex(
        ({ id }) => id === cardId
      );

      if (indexCard >= 0) {
        newGroups[indexGroup].cards.splice(indexCard, 1);

        setGroups(newGroups);
      }
    }
  };

  return {
    groups,

    getGroup,
    addGroup,
    updateGroup,
    removeGroup,

    getCard,
    addCard,
    updateCard,
    removeCard,
  };
};

export const ProvideGroup = ({ groups, children }) => {
  const ctx = useProvideGroup(groups);

  return <groupContext.Provider value={ctx}>{children}</groupContext.Provider>;
};

export const ConsumeGroup = ({ children }) => {
  return <groupContext.Consumer>{children}</groupContext.Consumer>;
};
