import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Section from "../Section";
import { Container, Image, Row, Col as ColBootstrap } from "react-bootstrap";
import firebase from "../../util/firebase";
import { useAuth } from "../../util/auth.js";
import ecwid from "../../ecwid";
import EmptyCart from "../../assets/images/undraw_empty_cart_co35.svg";
import { Link } from "react-router-dom";
import {
  checkIfOfferIsComplete,
  prepareStatus,
  optimizeRoute,
} from "./functions";

import Card from "./MyOffersRenderCard";
import PageLoader from "../PageLoader";

const db = firebase.firestore();
const MyOffers = (props) => {
  const auth = useAuth();

  // States
  const [offer, setOffer] = useState([]);
  const [loading, setLoading] = useState(true);

  // Handles
  const handleUpdateCard = async (data) => {
    if (offer && offer.orders && offer.orders.length) {
      const newOffer = { ...offer };
      const index = newOffer.orders.findIndex(({ id }) => id === data.id);
      if (index >= 0) {
        newOffer.orders.splice(index, 1, data);
        await prepareStatus(newOffer);
        await checkIfOfferIsComplete(newOffer);

        setOffer({ ...newOffer });
      }
    }
  };

  // Effects
  useEffect(() => {
    if (auth && auth.user && auth.user.id) {
      let didCancel = false;

      const getOrders = async () => {
        try {
          !didCancel && setLoading(true);

          const myOffer = await db
            .collection("offers")
            .where("acceptedAt", "==", auth.user.id)
            .where("status", "==", "OUT_FOR_DELIVERY")
            .get();

          if (!didCancel && myOffer.docs.length === 1) {
            const doc = myOffer.docs[0];
            const offer = { ...(await doc.data()), id: doc.id };

            // offer.orders = [];
            // for (let i = 300; i <= 400; i++) {
            //   offer.orders.push(i);
            // }
            offer.orders = await Promise.all(
              (offer.orders || []).map(async (orderNumber) => {
                try {
                  const order = await ecwid.getOrderDetails(orderNumber);

                  return order;
                } catch (err) {
                  return null;
                }
              })
            );

            offer.orders = offer.orders.filter((ord) => ord);
            await optimizeRoute(offer);
            await prepareStatus(offer);
            await checkIfOfferIsComplete(offer);

            !didCancel && setOffer(offer);
          }
        } catch (err) {
          console.error("Error ::>", err);
          !didCancel && setOffer(null);
        } finally {
          !didCancel && setLoading(false);
        }
      };

      getOrders();

      return () => (didCancel = true);
    }
  }, [auth]);

  return loading ? (
    <PageLoader />
  ) : (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      {offer && Array.isArray(offer.orders) && offer.orders.length ? (
        <Container fluid>
          <Row>
            {offer.orders.map((order) => (
              <Col lg={3} md={4} sm={6} xs={12} key={order.id}>
                <Card order={{ ...order }} onUpdateCard={handleUpdateCard} />
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <WrapperImage>
          <Image src={EmptyCart} fluid={true} />
          <figcaption>
            No tienes ofertas asignadas, <Link to="/offers">Ver Ofertas</Link>.
          </figcaption>
        </WrapperImage>
      )}
    </Section>
  );
};

const Col = styled(ColBootstrap)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const WrapperImage = styled.figure`
  width: 30%;
  margin: 0 auto;
  margin-top: 3rem;
`;

export default MyOffers;
