import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import firebase from "../../util/firebase";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Map = ({ userId }) => {
  const db = firebase.firestore();

  // States
  const [defaultCenter, setDefaultCenter] = useState(null);
  const [deliveryMan, setDeliveryMan] = useState(null);

  // Effects
  useEffect(() => {
    if (userId) {
      let didCancel = false;

      const getDeliveryMan = async () => {
        db.collection("users")
          .doc(userId)
          .onSnapshot((doc) => {
            if (!didCancel) {
              const deliveryMan = doc.data();
              // console.debug("deliveryMan", deliveryMan);

              defaultCenter || setDefaultCenter({ ...deliveryMan });
              setDeliveryMan({ ...deliveryMan });
            }
          });
      };

      getDeliveryMan();

      return () => (didCancel = true);
    }
  }, [userId, db, defaultCenter]);

  return !defaultCenter ? (
    <></>
  ) : (
    <>
      {/* <pre>defaultCenter:{JSON.stringify(defaultCenter, null, 2)}</pre> */}
      {/* <pre>position: {JSON.stringify(deliveryMan, null, 2)}</pre> */}
      <GoogleMap defaultZoom={16} defaultCenter={defaultCenter}>
        {deliveryMan && <Marker position={{ ...deliveryMan }} />}
      </GoogleMap>
    </>
  );
};

const MapComponent = withScriptjs(withGoogleMap(Map));

export default (props) => (
  <div style={{ flex: 1 }}>
    <MapComponent
      {...props}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?${
        GOOGLE_API_KEY ? `key=${GOOGLE_API_KEY}&` : ""
      }v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "80vh", width: "100%" }} />}
      mapElement={<div style={{ height: "100%" }} />}
    />
  </div>
);
