import React from "react";
import OffersSection from "../components/Offers";
import { requireAuth } from "../util/auth.js";

const OffersPage = ({ setTitle }) => {
  setTitle("Entregas");

  return (
    <OffersSection bg="white" textColor="dark" title="Entregas" subtitle="" />
  );
};

export default requireAuth(OffersPage);
