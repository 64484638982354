import React from "react";
import CreateRecipesForm from "../components/Recipes/createRecipesForm";
import { requireAuth } from "../util/auth.js";

const createRecipes = ({ setTitle }) => {
  setTitle("Crear Recetas");

  return (
    <CreateRecipesForm
      bg="white"
      textColor="dark"
      title="recetas"
      subtitle=""
    />
  );
};

export default requireAuth(createRecipes);
