import React from "react";
import MyOffersSection from "../components/MyOffers";
import { requireAuth } from "../util/auth.js";

const MyOffersPage = ({ setTitle }) => {
  setTitle("Mis ofertas");

  return (
    <MyOffersSection
      bg="white"
      textColor="dark"
      title="Mis ofertas"
      subtitle=""
    />
  );
};

export default requireAuth(MyOffersPage);
