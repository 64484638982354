import React from "react";

function NotFoundPage(props) {
  return (
    <div
      style={{
        padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >
      No se pudo encontrar la página <code>{props.location.pathname}</code>
    </div>
  );
}

export default NotFoundPage;
