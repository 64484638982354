import React, { useEffect, useState } from "react";
import ecwid from "../../ecwid";
import Map from "./Map";
import PageLoader from "../PageLoader";
import firebase from "../../util/firebase";

const Tracking = ({ google, id }) => {
  const db = firebase.firestore();

  // States
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    if (id) {
      let didCancel = false;

      const getOrder = async () => {
        try {
          setLoading(true);
          const order = await ecwid.getOrderDetails(id);

          if (!didCancel) {
            if (order) {
              // console.debug("order", order);
              const myOffers = await db
                .collection("offers")
                .where("orders", "array-contains", order.orderNumber)
                .get();

              if (!didCancel) {
                if (myOffers.docs.length === 1) {
                  const offer = await myOffers.docs[0].data();

                  if (!didCancel) {
                    // console.debug("offer", offer);
                    setUserId(offer.acceptedAt);
                  }
                }
              }
            }
          }
        } catch (err) {
          console.error("Error ::>", err);
        } finally {
          !didCancel && setLoading(false);
        }
      };

      getOrder();

      return () => {
        didCancel = true;
        setLoading(false);
      };
    }
  }, [id, db]);

  return loading ? <PageLoader /> : <Map userId={userId} />;
};

export default Tracking;
