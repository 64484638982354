import React, { useState } from "react";
//import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Section from "../Section";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";
import { purple } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import firebase from "../../util/firebase";

function RecipesIndex(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  //Firebase
  const db = firebase.firestore();

  //Get all ingredients
  getRecipes();

  async function getRecipes() {
    let recipes = [];
    let kcalTotal = 0;
    let res = await db.collection("recipes").get();
    res.docs.map(async (recipe, index) => {
      recipe.data().ingredients.map((ingredient, index) => {
        ingredient.nutrients.map((nutrient, index) => {
          if (nutrient.attr_id === 208) {
            kcalTotal = kcalTotal + nutrient.value;
          }
        });
      });
      recipes.push(
        createData(
          recipe.data().name,
          recipe.data().description,
          parseInt(kcalTotal),
          recipe.data().subRecipe ? "Sí" : "No"
        )
      );
    });
    setRows(recipes);
  }

  function createData(name, description, calories, subRecipe) {
    return { name, description, calories, subRecipe };
  }

  return (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <ColorButton
          color="primary"
          variant="contained"
          className={classes.margin}
          style={{ marginTop: 16, marginBottom: 16 }}
          href="/recipes/create"
        >
          Crear receta
        </ColorButton>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Receta</TableCell>
                <TableCell align="center">Descripción</TableCell>
                <TableCell align="center">
                  Contenido Energético&nbsp;(kcal)
                </TableCell>
                <TableCell align="center">Sub-Receta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.calories}</TableCell>
                  <TableCell align="center">{row.subRecipe}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Section>
  );
}

// const FormName = styled.div`
//   font-size: 16px;
//   margin: 20px;
// `;

// const FormSearch = styled.div`
//   width: 50%;
//   height: 640px;
//   margin: 20px;
//   border-radius: 6px;
//   border: 2px solid;
//   padding: 8px;
//   border-color: #d9d9d9;
// `;

// const FormContainer = styled.div`
//   width: 120%;
//   height: 700px;
//   margin: 20px;
//   border-radius: 6px;
//   border: 2px solid;
//   padding: 8px;
//   flex: 1;
//   flex-direction: row;
//   border-color: #d9d9d9;
// `;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#4BAD97",
//     "&$checked": {
//       color: "#4BAD97",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#4BAD97",
    "&:hover": {
      backgroundColor: "#4BAD97",
    },
  },
}))(Button);

export default RecipesIndex;
