import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth.js";
import Clock from "./Clock";
import styled from "styled-components";
import { NavLink, useLocation, useRouter } from "../util/router.js";
import { UseLocalStorage } from "./UseLocalStorage.js";


function NavbarCustom(props) {
  const auth = useAuth();
  const location = useLocation();
  const router = useRouter();
  // States
  const [routes, setRoutes] = useState([]);
  const [date, setDate] = UseLocalStorage("date",new Date());
  // const [showCompletes, setShowCompletes] = useState(false);
  // const [buttonOrderText, setButtonOrderText] = useState("Ocultar entregados");

  // Effects
  useEffect(() => {
    const includeOffer =
      props.routes.includes("/myOffers") || props.routes.includes("/offers");
    const hasMyOfferts =
      props.routes.includes("/myOffers") && !props.routes.includes("/offers");

    if (includeOffer) {
      if (location.pathname === "/offers" && hasMyOfferts) {
        router.push("/myOffers");
        return;
      } else if (location.pathname === "/myOffers" && !hasMyOfferts) {
        router.push("/offers");
        return;
      }
    }

    const routes = [
      { to: "/dashboard", text: "Órdenes" },
      includeOffer
        ? !hasMyOfferts
          ? { to: "/offers", text: "Entregas" }
          : { to: "/myOffers", text: "Mis ofertas" }
        : {},
      // { to: "/recipes", text: "Recetas" },
      // { to: "/ingredients", text: "Ingredientes" },
      { to: "/pivottable", text: "Estadísticas" },
      { to: "/settings/general", text: "Ajustes" },

      null,
      {
        to: "/auth/signout",
        text: "Cerrar sesión",
        onClick: (e) => {
          e.preventDefault();
          auth.signout();
        },
      },
    ];

    setRoutes(routes);
  }, [auth, props.routes, location, router]);


  // const handleButtonClicked = () => {
  //   setShowCompletes(!showCompletes ? true : false);
  //   setButtonOrderText(
  //     showCompletes ? "Ocultar completos" : "Mostrar completos"
  //   );
  //   //console.log("showCompletes: ", showCompletes);
  //   window.showOrders = showCompletes;
  //   //console.log("window.showOrders: ", window.showOrders);
  // };
  const handelFecha=(e)=>{
    setDate(e.target.value)
    window.location.reload();
  }

  return (
    <Navbar
    collapseOnSelect
    bg={props.bg}
    variant={props.variant}
    expand="md"
    sticky="top"
  >
    <Container fluid>
      <LinkContainer to="/dashboard">
        <Navbar.Brand>
          <img src={props.logo} alt="Logo" height="56 " width="200" />
        </Navbar.Brand>
      </LinkContainer>
      {/* 
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/offers">Entregas</Nav.Link>
          <Nav.Link href="#pricing">Pricing</Nav.Link>
        </Nav>
      </Navbar.Collapse> */}
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ">
          <li
            className="nav-item "
            style={{ marginRight: 24, fontSize: 16 }}
          >
            <a className={location.pathname === '/dashboard' ? "nav-link active" : "nav-link" } href="/dashboard">
              Órdenes <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item" style={{ marginRight: 24, fontSize: 16 }}>
            <a className={location.pathname === '/offers' ? "nav-link active" : "nav-link" } href="/offers">
              Entregas
            </a>
          </li>
          {/* DO TO */}
          <li className="nav-item" style={{ marginRight: 24, fontSize: 16 }}>
            <a className={location.pathname === '/offers' ? "nav-link active" : "nav-link" } href="/offers">
              Envíos
            </a>
          </li>
          <li className="nav-item" style={{ marginRight: 24, fontSize: 16 }}>
            <a className={location.pathname === '/pivottable' ? "nav-link active" : "nav-link" } href="/pivottable">
              Estadísticas
            </a>
          </li>
          <li className="nav-item" style={{ marginRight: 24, fontSize: 16 }}>
            <a className={location.pathname === '/inventory' ? "nav-link active" : "nav-link" } href="/inventory">
              Inventario
            </a>
          </li>
        </ul>
    </div>
     
      {/* <Title>{props.title || ""}</Title> */}

      <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav>
          {auth.user ? (
            <>
              
              {/* {location && location.pathname == "/dashboard" ? (
                <Button
                  type="submit"
                  onClick={handleButtonClicked}
                  style={{ marginRight: 32 }}
                >
                  <span>{buttonOrderText}</span>
                </Button>
              ) : null} */}
              <input 
                aria-label="Fecha" 
                type="date" 
                value={date}
                className="btn btn-outline-light"
                onChange={(e)=>{handelFecha(e)}}
              />

              <ClockWrap className="px-3">
                <Clock format={"hh-mm"} />
              </ClockWrap>

              <NavDropdown
                id="dropdown"
                title="Opciones"
                alignRight={true}
                style={{ fontSize: 16 }}
              >
                {routes.map((route, index) => (
                  <React.Fragment key={index}>
                    {route ? (
                      <>
                        <LinkContainer to={route.to}>
                          <NavDropdown.Item
                            active={false}
                            onClick={route.onClick || null}
                          >
                            {route.text}
                          </NavDropdown.Item>
                        </LinkContainer>
                      </>
                    ) : (
                      <>
                        <Dropdown.Divider />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </NavDropdown>
            </>
          ) : (
            <Nav.Item>
              <LinkContainer to="/auth/signin">
                <Nav.Link active={false}>Registrarse</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

    
  );
}

const Title = styled.span`
  font-size: 0.8rem;
  font-family: sans-serif;
  margin-bottom: 0;
  padding-left: 48px;
  color: white;
`;
const ClockWrap = styled.div`
  padding: 4px;
  margin-right: 20px;
`;

export default NavbarCustom;
