import firebase from "../util/firebase";
const functions = firebase.functions();

const kitchen = {
  street: "Lago Onega 156, Modelo Pensil, Miguel Hidalgo",
  city: "Ciudad de México",
  stateOrProvinceName: "CDMX",
  stateOrProvinceCode: "CDMX",
  postalCode: "11450",
  countryCode: "MX",
};

const fix = (...fields) => {
  return (fields || []).reduce((waypoint, field) => {
    if (field && field.length) {
      return `${waypoint && waypoint.length ? `${waypoint}, ` : ""}${field}`;
    }

    return waypoint;
  }, "");
};

const getWaypoint = ({ street, city, stateOrProvinceName, countryName }) => {
  return fix(street, city, stateOrProvinceName, countryName);
};

export const optimizeOffer = async (offer) => {
  try {
    const optimizeRoute = functions.httpsCallable("optimizeRoute");

    const origin = getWaypoint(kitchen);
    const waypoints = offer.orders.map(({ shippingPerson }) =>
      getWaypoint(shippingPerson)
    );
    const res = await optimizeRoute({
      origin,
      destination: origin,
      waypoints,
    });

    if (res && res.data) {
      return res.data;
    }
  } catch (err) {
    console.error("Error ::> ", err);
  }

  return null;
};
