import React, { useState } from "react";
import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import firebase from "../../util/firebase";
import { useAuth } from "../../util/auth.js";
import { useToasts } from "react-toast-notifications";

const db = firebase.firestore();
const OffersRenderHeader = ({ group: { id, title }, onRemoveGroup }) => {
  const auth = useAuth();
  const { addToast } = useToasts();

  // State
  const [loading, setLoading] = useState(false);

  // Handles
  const handleAcceptClick = async () => {
    try {
      setLoading(true);
      const offerRef = await db.collection("offers").doc(id);
      await offerRef.set(
        {
          acceptedAt: (auth && auth.user && auth.user.id) || null,
          updatedAt: new Date(),
        },
        { merge: true }
      );
      addToast("Oferta aceptada", { appearance: "success", autoDismiss: true });

      onRemoveGroup && onRemoveGroup();
    } catch (err) {
      addToast("Oferta no aceptada", {
        appearance: "error",
        autoDismiss: true,
      });
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderColumn>
        <span>{title}</span>
        <Button variant="warning" size="sm" onClick={handleAcceptClick}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Aceptar oferta"
          )}
        </Button>
      </HeaderColumn>
    </>
  );
};

const HeaderColumn = styled.div`
  font-size: 15px;
  color: black;
  font-weight: normal;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export default OffersRenderHeader;
