import React from "react";
import InventorySection from "../components/Inventory";
import { requireAuth } from "../util/auth.js";

const InventoryPage = ({ setTitle }) => {
  setTitle("Inventario");

  return (
    <InventorySection
      bg="white"
      textColor="dark"
      title="Inventario"
      subtitle=""
    />
  );
};

export default requireAuth(InventoryPage);
