import React, { useMemo } from "react";
import ecwid from "../../ecwid";
import { getCurrentPosition } from "../../services/GeoLocation";
import { useToasts } from "react-toast-notifications";
import OrderCard from "../OrderCard";

const MyOffersRenderCard = ({ order, onUpdateCard }) => {
  const { addToast } = useToasts();

  // Memos
  const status = useMemo(() => {
    if (order) {
      let status = { cursor: true };
      if (order.waitPrevOrders) {
        status.color = "dark";
        status.text = "En espera";
        status.cursor = false;
      } else {
        switch (order.fulfillmentStatus) {
          case "OUT_FOR_DELIVERY":
            status.color = "danger";
            status.text = "Iniciar entrega";
            break;
          case "SHIPPED":
            status.color = "warning";
            status.text = "Entregar";
            break;
          case "DELIVERED":
            status.color = "secondary";
            status.text = "Completado";
            status.cursor = false;
            break;

          default:
            status.color = "info";
            status.text = "Invalido";
            status.cursor = false;
            break;
        }
      }

      return status;
    }

    return null;
  }, [order]);

  // Handles
  const handleActionClick = async (event) => {
    if (
      status &&
      status.cursor &&
      ["OUT_FOR_DELIVERY", "SHIPPED"].includes(order.fulfillmentStatus)
    ) {
      event.stopPropagation();
      let paymentStatus;
      const fulfillmentStatus =
        order.fulfillmentStatus === "OUT_FOR_DELIVERY"
          ? "SHIPPED"
          : "DELIVERED";

      if (
        order.discountCoupon &&
        order.discountCoupon.orderCount === 0 &&
        order.paymentStatus === "AWAITING_PAYMENT"
      ) {
        paymentStatus = order.paymentStatus = "PAID";
      }

      await ecwid.updateOrder(order.orderNumber, {
        fulfillmentStatus,
        paymentStatus,
      });

      onUpdateCard && onUpdateCard({ ...order, fulfillmentStatus });
    }
  };

  const handleRouteClick = async (event) => {
    if (order && order.daddr) {
      const pos = await getCurrentPosition();
      if (pos && pos.latitude && pos.longitude) {
        window.open(
          `http://maps.google.com/?saddr=${pos.latitude},${pos.longitude}&daddr=${order.daddr}&dirflg=d`,
          "_blank"
        );
      } else {
        addToast(pos, { appearance: "error", autoDismiss: true });
      }
    }
  };

  return (
    <OrderCard
      order={order}
      status={status}
      onActionClick={handleActionClick}
      onRouteClick={order && order.daddr && handleRouteClick}
    />
  );
};

export default MyOffersRenderCard;
