const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const success = (pos) => {
  // var crd = pos.coords;
  // console.log("Your current position is:");
  // console.log("Latitude : " + crd.latitude);
  // console.log("Longitude: " + crd.longitude);
  // console.log("More or less " + crd.accuracy + " meters.");

  return pos;
};

const error = (err) => {
  let msg;
  switch (err.code) {
    case 1: // GeolocationPositionError.PERMISSION_DENIED
      msg = "Necesita proporcionar permisos para acceder a la ubicación.";
      break;

    default:
      console.warn("ERROR(" + err.code + "): " + err.message);

      msg = "Algo salio mal.";
      break;
  }

  return msg;
};

export const getCurrentPosition = async () => {
  try {
    const res = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(success(pos).coords);
        },
        (err) => {
          reject(error(err));
        },
        options
      );
    });

    if (res && res.latitude && res.longitude) {
      return res;
    }
  } catch (err) {
    console.error("Error ::>", err);
    return err;
  }

  return "Algo salio mal.";
};

export const watchPosition = (resolve, reject) => {
  try {
    return navigator.geolocation.watchPosition(
      resolve
        ? (pos) => {
            let ret = success(pos).coords;
            if (!ret || !ret.latitude || !ret.longitude) {
              console.warn("ERROR ::> Sin coordenadas", ret);
              reject("Algo salio mal.");
            }

            resolve(ret);
          }
        : null,
      reject
        ? (err) => {
            const ret = error(err);

            reject(ret);
          }
        : null,
      { ...options, enableHighAccuracy: false }
    );
  } catch (err) {
    console.error("Error ::>", err);
  }

  return null;
};
