import firebase from "../../util/firebase";
import { optimizeOffer } from "../../services/RouteOfferOptimization";
const db = firebase.firestore();

export const checkIfOfferIsComplete = async (offer) => {
  const offerComplete = offer.orders.every(
    ({ fulfillmentStatus }) => fulfillmentStatus === "DELIVERED"
  );
  let oldStatus = offer.status;

  if (offerComplete && offer.status === "OUT_FOR_DELIVERY") {
    offer.status = "DELIVERED";
  } else if (!offerComplete && offer.status === "DELIVERED") {
    offer.status = "OUT_FOR_DELIVERY";
  }

  if (oldStatus !== offer.status) {
    await db
      .collection("offers")
      .doc(offer.id)
      .set({ status: offer.status, updatedAt: new Date() }, { merge: true });
  }
};

export const prepareStatus = async (offer) => {
  let stepFound = false;
  offer.orders.forEach((order) => {
    order.waitPrevOrders = false;
    if (stepFound) {
      order.waitPrevOrders = true;
    } else if (
      ["OUT_FOR_DELIVERY", "SHIPPED"].includes(order.fulfillmentStatus)
    ) {
      stepFound = true;
    } else if (order.fulfillmentStatus !== "DELIVERED") {
      order.fulfillmentStatus = null;
    }
  });
};

export const optimizeRoute = async (offer) => {
  const res = await optimizeOffer(offer);
  if (
    res &&
    res.waypointOrder &&
    res.waypointOrder.length === offer.orders.length &&
    res.waypoints &&
    res.waypoints.length === offer.orders.length
  ) {
    offer.orders = res.waypointOrder.map((indexOrder, indexPoint) => ({
      ...offer.orders[indexOrder],
      daddr:
        (res.waypoints[indexPoint] &&
          encodeURIComponent(res.waypoints[indexPoint])) ||
        null,
    }));
  }
};
