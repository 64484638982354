import React from "react";
import DashboardSection from "../components/Dashboard";
import { requireAuth } from "../util/auth.js";

const DashboardPage = ({ setTitle }) => {
  setTitle("Órdenes");

  return (
    <DashboardSection bg="white" textColor="dark" title="Órdenes" subtitle="" />
  );
};

export default requireAuth(DashboardPage);
