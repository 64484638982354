import React, { useState } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Section from "../Section";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { purple } from "@material-ui/core/colors";
import firebase from "../../util/firebase";
import { useToasts } from "react-toast-notifications";

function CreateIngredientsForm(props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [portion, setPortion] = useState("");
  const [kcal, setKcal] = useState("");
  const [totalFat, setTotalFat] = useState("");
  const [saturatedFat, setSaturatedFat] = useState("");
  const [polyunsaturatedFat, setPolyunsaturatedFat] = useState("");
  const [monounsaturatedFat, setMonounsaturatedFat] = useState("");
  const [transFat, setTransFat] = useState("");
  const [cholesterol, setCholesterol] = useState("");
  const [sodium, setSodium] = useState("");
  const [carbohydrates, setCarbohydrates] = useState("");
  const [fiber, setFiber] = useState("");
  const [totalStamp, setTotalStamp] = useState("");
  const [proteins, setProteins] = useState("");
  const [sugars, setSugars] = useState("");
  const [addedSugars, setAddedSugars] = useState("");
  //Firebase
  const db = firebase.firestore();
  //Toast
  const { addToast } = useToasts();

  const addIngredient = async () => {
    if (name !== "") {
      await db
        .collection("ingredients")
        .doc(name)
        .set({
          name: name,
          amount: parseFloat(portion),
          unit: "g",
          nutrients: [
            {
              attr_id: 203,
              name: "protein",
              unit: "g",
              value: parseFloat(proteins),
            },
            {
              attr_id: 204,
              name: "totalFlat",
              unit: "g",
              value: parseFloat(totalFat),
            },
            {
              attr_id: 205,
              name: "carbohydrates",
              unit: "g",
              value: parseFloat(carbohydrates),
            },
            {
              attr_id: 208,
              name: "kcal",
              unit: "g",
              value: parseFloat(kcal),
            },
            {
              attr_id: 203,
              name: "protein",
              unit: "g",
              value: parseFloat(proteins),
            },
            {
              attr_id: 269,
              name: "sugars",
              unit: "g",
              value: parseFloat(sugars),
            },
            {
              attr_id: 291,
              name: "fiber",
              unit: "g",
              value: parseFloat(fiber),
            },
            {
              attr_id: 307,
              name: "sodium",
              unit: "mg",
              value: parseFloat(sodium),
            },
            {
              attr_id: 601,
              name: "cholesterol",
              unit: "mg",
              value: parseFloat(cholesterol),
            },
            {
              attr_id: 605,
              name: "transFat",
              unit: "g",
              value: parseFloat(transFat),
            },
            {
              attr_id: 606,
              name: "saturatedFat",
              unit: "g",
              value: parseFloat(saturatedFat),
            },
            {
              attr_id: 645,
              name: "monounsaturatedFat",
              unit: "g",
              value: parseFloat(monounsaturatedFat),
            },
            {
              attr_id: 646,
              name: "polyunsaturatedFat",
              unit: "g",
              value: parseFloat(polyunsaturatedFat),
            },
            {
              attr_id: 1,
              name: "totalStamp",
              unit: "sello",
              value: parseFloat(totalStamp),
            },
          ],
        });
      addToast("Ingrediente creado", {
        appearance: "success",
        autoDismiss: true,
      });

      //Redirect to IngredientsIndex
      setTimeout(function () {
        window.location.href = "/ingredients/";
      }, 1500);
    }
  };

  return (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <FormContainer>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            id="outlined-basic"
            label="Nombre del ingrediente"
            variant="outlined"
            style={{ margin: 20, width: "50%" }}
            color="#4BAD97"
          ></TextField>
          <ColorButton
            color="primary"
            variant="contained"
            className={classes.margin}
            onClick={() => addIngredient()}
            style={{ margin: 20 }}
          >
            Crear Ingrediente
          </ColorButton>
          <FormValues>
            <TextField
              value={portion}
              onChange={(event) => setPortion(event.target.value)}
              id="outlined-basic"
              label="Porción (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={kcal}
              onChange={(event) => setKcal(event.target.value)}
              id="outlined-basic"
              label="Contenido energético (kcal)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={proteins}
              onChange={(event) => setProteins(event.target.value)}
              id="outlined-basic"
              label="Proteínas (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={totalFat}
              onChange={(event) => setTotalFat(event.target.value)}
              id="outlined-basic"
              label="Grasas Totales (g)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={saturatedFat}
              onChange={(event) => setSaturatedFat(event.target.value)}
              id="outlined-basic"
              label="Grasas Saturadas (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={polyunsaturatedFat}
              onChange={(event) => setPolyunsaturatedFat(event.target.value)}
              id="outlined-basic"
              label="Grasas Poliinsaturadas (g)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={monounsaturatedFat}
              onChange={(event) => setMonounsaturatedFat(event.target.value)}
              id="outlined-basic"
              label="Grasas Monoinsaturadas (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={transFat}
              onChange={(event) => setTransFat(event.target.value)}
              id="outlined-basic"
              label="Grasa Trans (g)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={carbohydrates}
              onChange={(event) => setCarbohydrates(event.target.value)}
              id="outlined-basic"
              label="Carbohidratos Totales (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={sugars}
              onChange={(event) => setSugars(event.target.value)}
              id="outlined-basic"
              label="Azúcares (g)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={addedSugars}
              onChange={(event) => setAddedSugars(event.target.value)}
              id="outlined-basic"
              label="Azúcares añadidos (g)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={fiber}
              onChange={(event) => setFiber(event.target.value)}
              id="outlined-basic"
              label="Fibra dietética (g)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={sodium}
              onChange={(event) => setSodium(event.target.value)}
              id="outlined-basic"
              label="Sodio (mg)"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={cholesterol}
              onChange={(event) => setCholesterol(event.target.value)}
              id="outlined-basic"
              label="Colesterol (mg)"
              type="number"
              variant="outlined"
              style={{ marginTop: 16, marginLeft: 54, width: "45%" }}
              color="#4BAD97"
            ></TextField>
            <TextField
              value={totalStamp}
              onChange={(event) => setTotalStamp(event.target.value)}
              id="outlined-basic"
              label="Cantidad de sellos"
              type="number"
              variant="outlined"
              style={{ margin: 16, width: "45%" }}
              color="#4BAD97"
            ></TextField>
          </FormValues>
        </FormContainer>
      </Container>
    </Section>
  );
}

const FormValues = styled.div`
  width: 100%;
  margin: 20px;
  border-radius: 6px;
  border: 2px solid;
  padding: 8px;
  flex: 1;
  flex-direction: row;
  border-color: #d9d9d9;
`;

const FormContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 480,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#4BAD97",
//     "&$checked": {
//       color: "#4BAD97",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#4BAD97",
    "&:hover": {
      backgroundColor: "#4BAD97",
    },
    height: 54,
  },
}))(Button);

export default CreateIngredientsForm;
