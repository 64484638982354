import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Section from "../Section";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";
import { purple } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import firebase from "../../util/firebase";

function IngredientsIndex(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  //Firebase
  const db = firebase.firestore();

  //Get all ingredients
  getIngredients();

  async function getIngredients() {
    let ingredients = [];
    let kcalTotal;
    let res = await db.collection("ingredients").get();
    res.docs.map(async (ingredient, index) => {
      ingredient.data().nutrients.map((nutrient, index) => {
        if (nutrient.attr_id === 208) {
          kcalTotal = nutrient.value;
        }
      });
      ingredients.push(
        createData(
          ingredient.data().name,
          ingredient.data().amount,
          ingredient.data().unit,
          kcalTotal
        )
      );
    });
    setRows(ingredients);
  }

  function createData(name, amount, unit, calories) {
    return { name, amount, unit, calories };
  }

  return (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <ColorButton
          color="primary"
          variant="contained"
          className={classes.margin}
          href="/ingredients/create"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          Crear ingrediente
        </ColorButton>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ingrediente</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell align="center">Unidad</TableCell>
                <TableCell align="center">
                  Contenido Energético&nbsp;(kcal)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.amount}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {row.unit}
                  </TableCell>
                  <TableCell align="center">{row.calories}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Section>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#4BAD97",
//     "&$checked": {
//       color: "#4BAD97",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#4BAD97",
    "&:hover": {
      backgroundColor: "#4BAD97",
    },
  },
}))(Button);

export default IngredientsIndex;
