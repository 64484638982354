import React from "react";
import HeroSection from "./../components/HeroSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Envia tus órdenes a los clientes"
        subtitle="Visualiza y organiza tus ordenes para tu restaurante."
        buttonText="Comienza ahora"
        buttonColor="primary"
        image={require("../assets/images/delivery.svg")}
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/dashboard");
        }}
      />
    </>
  );
}

export default IndexPage;
