import React from "react";
import Section from "../Section";
import Container from "react-bootstrap/Container";
import DashboardOrders from "./Orders";
import "./Dashboard.scss";

function Dashboard(props) {
  return (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid>
        <DashboardOrders />
      </Container>
    </Section>
  );
}

export default Dashboard;
