import React, { useState, useEffect } from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import DashboardPage from "./dashboard";
import TrackingPage from "./tracking";
import OffersPage from "./offers";
import MyOffersPage from "./myOffers";
import CreateRecipes from "./createRecipes";
import CreateIngredients from "./createIngredients";
import Recipes from "./recipesList";
import Ingredients from "./ingredientsList";
import SettingsPage from "./settings";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import PivotTablePage from "./pivottable";
import NotFoundPage from "./not-found.js";
// import Footer from "./../components/Footer";
import "./../util/analytics.js";
//import geolocation from "../util/geolocation";
import { useAuth } from "../util/auth.js";
import firebase from "../util/firebase";
import InventoryPage from "./inventory";

const AllRoutes = [
  { path: "/", component: IndexPage },
  { path: "/dashboard", component: DashboardPage },
  { path: "/offers", component: OffersPage },
  { path: "/myOffers", component: MyOffersPage },
  { path: "/tracking/:id", component: TrackingPage },
  { path: "/recipes", component: Recipes },
  { path: "/recipes/create", component: CreateRecipes },
  { path: "/ingredients", component: Ingredients },
  { path: "/ingredients/create", component: CreateIngredients },
  { path: "/settings/:section", component: SettingsPage },
  { path: "/auth/:type", component: AuthPage },
  { path: "/firebase-action", component: FirebaseActionPage },
  { path: "/pivottable", component: PivotTablePage },
  { path: "/inventory", component: InventoryPage}
];

const App = (props) => {
  //geolocation();
  const auth = useAuth();
  const db = firebase.firestore();

  // States
  const [title, setTitle] = useState(false);
  const [routes, setRoutes] = useState([]);

  // Effects
  useEffect(() => {
    if (auth && auth.user && auth.user.id) {
      let didCanel = false;

      db.collection("offers")
        .where("acceptedAt", "==", auth.user.id)
        .where("status", "==", "OUT_FOR_DELIVERY")
        .onSnapshot((querySnapshot) => {
          if (!didCanel) {
            // console.debug("querySnapshot", querySnapshot.docs.length);
            const hasOffers = querySnapshot.docs.length === 1;

            setRoutes(
              AllRoutes.filter(({ path }) => {
                if (
                  (path === "/myOffers" && !hasOffers) ||
                  (path === "/offers" && hasOffers)
                ) {
                  return false;
                }

                return true;
              })
            );
          }
        });

      return () => (didCanel = true);
    }

    setRoutes(
      AllRoutes.filter(({ path }) => !["/offers", "/myOffers"].includes(path))
    );
  }, [auth, db]);

  return (
    <Router>
      <>
        <NavbarCustom
          bg="header"
          variant="dark"
          expand="md"
          logo={require("../assets/images/LogoBambu.svg")}
          routes={routes.map(({ path }) => path)}
          title={title}
        />

        <Switch>
          {routes.map(({ path, component: Component }, index) => (
            <Route exact path={path} key={index}>
              {() => {
                setTitle(null);

                return <Component setTitle={setTitle} />;
              }}
            </Route>
          ))}
          <Route component={NotFoundPage} />
        </Switch>
        {/* <Footer
          bg="light"
          textColor="dark"
          size="sm"
          bgImage=""
          bgImageOpacity={1}
          description="A short description of what you do here"
          copyright="© Bambu Delivery"
          logo="https://uploads.divjoy.com/logo.svg"
        /> */}
      </>
    </Router>
  );
};

export default App;
