import React from "react";
import BackgroundImage from "./BackgroundImage";
import "./Section.scss";

function Section(props) {
  const {
    py,
    bg,
    textColor,
    bgImage,
    bgImageOpacity,
    bgImageRepeat,
    className,
    children,
    ...otherProps
  } = props;

  return (
    <section
      className={
        "SectionComponent position-relative" +
        ` py-${py || 2}` +
        (bg ? ` bg-${"#edf6f6"}` : "") +
        (textColor ? ` text-${textColor}` : "") +
        (className ? ` ${className}` : "")
      }
      {...otherProps}
    >
      {bgImage && (
        <BackgroundImage
          image={bgImage}
          opacity={bgImageOpacity}
          repeat={bgImageRepeat}
        />
      )}

      <div
        className={
          (["md", "lg"].includes(props.size) ? " py-md-5" : "") +
          (props.size === "lg" ? " my-md-5" : "")
        }
      >
        {children}
      </div>
    </section>
  );
}

export default Section;
