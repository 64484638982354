import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "@lourenci/react-kanban/dist/styles.css";
import ecwid from "../../ecwid";
import groupByMethod from "./OrdersGroupMethods";
import RenderCard from "./OrdersRenderCard";
import RenderHeader from "./OrdersRenderHeader";
// import audioAlert from "../../assets/alert.mp3";
import AMomentToRelax from "../../assets/images/Bambu-Lomas-Logo.png";
import "./Orders.scss";
import { Image } from "react-bootstrap";
import PageLoader from "../PageLoader";
import Kanban from "../Kanban";
import firebase from "../../util/firebase";

function DashboardSection(props) {
  const db = firebase.firestore();
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCompletes, setShowCompletes] = useState(false);

  let getOrders = async () => {
    let resultStoreProfile = await ecwid.searchOrders();
    //console.log("resultStoreProfile: ", resultStoreProfile);
    let resultGroupMethod = groupByMethod(
      resultStoreProfile.items,
      showCompletes
    );
    setOrders(resultGroupMethod);
    setLoading(false);
  };

  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  // const soundAlert = () => {
  //   // var audio = new Audio(audioAlert);
  //   // audio.play();
  // };

  // useEffect(() => {
  //   console.log("statusOrders---->", window.showOrders);
  // }, [window.showOrders]);

  useEffect(() => {
    setLoading(true);
    const eventsRef = db.collection("events");

    var unsubscribe = eventsRef
      .where("storeId", "==", 16938865)
      .onSnapshot((datos) => {
        setLoading(true);
        getOrders();
      });

    return unsubscribe;
    
  }, []);

  return loading ? (
    <PageLoader />
  ) : (
    <>
      {orders && orders.length ? (
        <Kanban
          groups={orders}
          renderColumnHeader={(group, { updateGroup }) => (
            <RenderHeader group={group} onUpdateGroup={updateGroup} />
          )}
          renderCard={(card, { updateCard }) => (
            <RenderCard order={card} onUpdateCard={updateCard} />
          )}
        />
      ) : (
        <WrapperImage>
          <Image src={AMomentToRelax} fluid={true} />
          {/* <figcaption>No existen órdenes.</figcaption> */}
        </WrapperImage>
      )}
    </>
  );
}

const WrapperImage = styled.figure`
  width: 16%;
  margin: 0 auto;
  margin-top: 8rem;
`;

export default DashboardSection;
