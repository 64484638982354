import React from "react";
import IngredientsList from "../components/Ingredients/ingredientsIndex";
import { requireAuth } from "../util/auth.js";

const ingredientsList = ({ setTitle }) => {
  setTitle("Ingredientes");

  return (
    <IngredientsList
      bg="white"
      textColor="dark"
      title="ingredientes"
      subtitle=""
    />
  );
};

export default requireAuth(ingredientsList);
