import React from "react";
import { ProvideGroup, ConsumeGroup } from "./GroupContext";
import Board from "@lourenci/react-kanban";
import styled from "styled-components";

const Kanban = ({ groups, renderColumnHeader, renderCard, ...otherProps }) => {
  return (
    <ProvideGroup groups={groups}>
      <WrapperBoard>
        <ConsumeGroup>
          {(ctx) => {
            return (
              <Board
                {...otherProps}
                disableCardDrag={true}
                disableColumnDrag={true}
                renderColumnHeader={({ id }) => {
                  const group = ctx.getGroup(id);

                  if (group) {
                    if (renderColumnHeader) {
                      const handleAddGroup = (group) => {
                        ctx.addGroup(group);
                      };

                      const handleUpdateGroup = (group) => {
                        ctx.updateGroup(id, group);
                      };

                      const handleRemoveGroup = () => {
                        ctx.removeGroup(id);
                      };

                      return renderColumnHeader(group, {
                        addGroup: handleAddGroup,
                        updateGroup: handleUpdateGroup,
                        removeGroup: handleRemoveGroup,
                      });
                    }

                    return group.title || "S/G";
                  }

                  return "S/G";
                }}
                renderCard={({ id, groupId }) => {
                  const card = ctx.getCard(groupId, id);

                  if (card) {
                    if (renderCard) {
                      const handleAddCard = (card) => {
                        ctx.addCard(groupId, card);
                      };

                      const handleUpdateCard = (card) => {
                        ctx.updateCard(groupId, id, card);
                      };

                      const handleRemoveCard = () => {
                        ctx.removeCard(groupId, id);
                      };

                      return renderCard(card, {
                        addCard: handleAddCard,
                        updateCard: handleUpdateCard,
                        removeCard: handleRemoveCard,
                      });
                    }

                    return card.title || "S/C";
                  }

                  return "S/C";
                }}
              >
                {{
                  columns: (
                    (Array.isArray(ctx.groups) && ctx.groups) ||
                    []
                  ).map(({ id: groupId, cards }) => ({
                    id: groupId,
                    cards: cards.map(({ id: cardId }) => ({
                      id: cardId,
                      groupId,
                    })),
                  })),
                }}
              </Board>
            );
          }}
        </ConsumeGroup>
      </WrapperBoard>
    </ProvideGroup>
  );
};

const WrapperBoard = styled.div`
  & > div.react-kanban-board > div > div.react-kanban-column {
    padding: 0.5rem;
    margin: 0.25rem;
    //background-color: #edf6f6;
  }
`;

export default Kanban;
